<template>
  <div class="available-on">
    <Head>
      <title>Shift Zone - Buy</title>
      <meta
        name="description"
        content="Shift Zone is available on Shopee and LAzada"
      />
    </Head>
    <h1 class="buy-links">Available on</h1>
    <div class="shop-links">
      <div @click="openShopeeLink" class="shop-link">
        <img
          v-bind:class="[getIconClass, 'shop-icon']"
          src="../assets/svg/icons8-shopee.svg"
          alt="Follow us on Shopee"
        />
        <span class="btn">Shopee</span>
      </div>
      <div @click="openLazLink" class="shop-link">
        <img
          v-bind:class="[getIconClass, 'shop-icon']"
          src="../assets/svg/icons8-lazada.svg"
          alt="Follow us on Lazada"
        />
        <span class="btn">Lazada</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Buy",
  props: {
    centerText: String,
  },
  inject: ["mq"],
  methods: {
    openLazLink() {
      window.open("https://www.lazada.com.ph/shop/shiftzonetech");
    },
    openShopeeLink() {
      window.open("https://shopee.ph/shiftzone.tech");
    },
    openFbLink() {
      window.open("https://facebook.com/shiftzone.tech/");
    },
    openFbApp() {
      window.location = "fb://page/101199492217741/";
    },
  },
  computed: {
    getIconClass() {
      if (this.mq === "sm" || this.mq === "xs") {
        return "icon-mobile";
      } else {
        return "icon-desktop";
      }
    },
  },
};
</script>


<style scoped>
.available-on {
  display: flex;
  flex-direction: column;
}
.shop-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}
.shop-link {
  cursor: pointer;
  padding: 2vw;
  box-sizing: border-box;
}
.shop-link:hover {
  border-bottom: 5px solid var(--mint-green);
}
.btn {
  cursor: pointer;
  font-family: "Baron";
  font-size: 8vw;
  margin: 2vw;
}
.shop-icon.icon-desktop {
  height: 100px;
}
.shop-icon.icon-mobile {
  height: 30px;
}
</style>