<template>
  <div class="sz-filter">
    <div class="all-none">
      <span
        v-bind:class="[isActive ? '' : '', 'filter-btn all']"
        v-on:click="selectAll"
      >
        All {{ filterName }}
      </span>

      <span
        v-bind:class="[isActive ? '' : '', 'filter-btn none']"
        v-on:click="removeAll"
      >
        Clear Filters
      </span>
    </div>

    <div class="filter-btns">
      <div
        class="filter-btn"
        v-for="cat in getCategories()"
        v-bind:key="cat.id"
      >
        <label :for="cat.id" class="lbl">
          <input
            type="checkbox"
            :id="cat.id"
            v-on:change="activateTag"
            v-model="szActiveCats"
            :value="cat.id"
            :checked="isChecked(cat.id)"
          />
          {{ cat.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SZFilter",
  props: {
    /* array of obj {id, name} */
    categories: Array,
    filterName: String,
  },
  data: () => {
    return {
      szActiveCats: [],
    };
  },
  watch: {
    categories: {
      handler() {
        // bug due to this emiting 
        // this.szActiveCats = this.categories.map((cat) => cat.id); // bydefault on all
        // this.$emit("filter", this.szActiveCats);
        this.szActiveCats = this.$store.getters.getFilter(this.filterName);
      },
      immediate: true,
    },
  },
  computed: {
    isActive: function () {
      return true;
    },
  },
  methods: {
    selectAll: function () {
      this.categories.forEach((cat) => {
        if (!this.szActiveCats.includes(cat.id)) {
          this.szActiveCats.push(cat.id);
        }
      });
      this.$emit("filter", this.szActiveCats);
    },
    removeAll: function () {
      this.szActiveCats.splice(0);

      this.$emit("filter", this.szActiveCats);
    },
    getCategories: function () {
      return this.categories;
    },
    activateTag: function () {
      console.log('ey')
      this.$emit("filter", this.szActiveCats);
      this.$store.commit("updateFilter", {
        name: this.filterName,
        data: this.szActiveCats,
      });
    },
    isChecked: function (id) {
      return this.szActiveCats.includes(id);
    },
  },
};
</script>

<style scoped>
.sz-filter {
  display: flex;
  color: var(--white);
  width: auto;
  flex-wrap: wrap;
  gap: 2px;
  flex-direction: column;
}
.sz-filter .all-none {
  display: flex;
  gap: 2px;
  margin-bottom: 2px;
}

.sz-filter .filter-btn.all,
.sz-filter .filter-btn.none {
  padding: 10px;
  cursor: pointer;
  height: 30px;
  background: none;
  color: var(--grey);
  border: 1px solid var(--grey);
  font-family: "Baron";
  font-size: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.sz-filter .filter-btns {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.sz-filter .filter-btn {
  font-size: 18px;
  background-color: var(--grey);
  color: var(--white);
  box-sizing: border-box;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 10px;
}

.sz-filter .filter-btn .lbl {
  padding: 10px;
  cursor: pointer;
  accent-color: var(--mint-green);
}

.sz-cats {
  display: flex;
}

.sz-filter span.filter-tag {
  padding: 10px;
  align-items: center;
  cursor: pointer;
  background-color: var(--grey);
}

.sz-filter span.filter-tag:hover {
  background: black;
}
.filter-tag {
  display: none;
}

.filter-tag.show {
  display: flex;
}
</style>