<template>
  <div class="product-list">
    <div class="product-header">
      <div class="header product-header-text">
        <span>{{ sectionName }}</span>
        <div
          v-if="editable"
          class="sz-button white"
          @click="headerClicked"
        >
          Edit
        </div>
      </div>
    </div>
    <div :class="getProductCardListClass">
      <ProductCard
        v-for="product in products"
        :key="product.id"
        v-bind:id="product.id"
        v-bind:imgUrl="product.imgUrl"
        v-bind:shortDesc="product.shortDesc"
        v-bind:links="product.links"
        v-bind:name="product.name"
        v-bind:altText="product.altText"
        v-bind:price="product.price"
        v-bind:salePrice="product.salePrice"
      />

      <div class="add-product-card" v-if="editable">
        <div class="sz-button"><router-link :to="`/${categoryId}/product`">Add Product</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@szc/catalog/ProductCard.vue";

export default {
  name: "ProductList",
  components: {
    ProductCard,
  },
  inject: ["mq"],
  props: {
    sectionId: Number,
    sectionName: String,
    products: Array,
    editable: Boolean,
  },
  computed: {
    getProductCardListClass() {
      var reactiveClass = "product-cards-xxl";

      if (this.mq === "xs" || this.mq === "sm") {
        reactiveClass = "product-cards-mob";
      } else if (this.mq === "md") {
        reactiveClass = "product-cards-3";
      } else if (this.mq === "lg" || this.mq === "xl") {
        reactiveClass = "product-cards-4";
      }

      return reactiveClass;
    },
    categoryId() {
      return this.$route.params.categoryId;
    },
  },
  methods: {
    headerClicked() {
      console.log(this.sectionid);
      this.$emit("headerClicked", this.sectionId);
    },
  },
};
</script>

<style scoped>
.product-header-text {
  font-size: 36px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.clickable {
  cursor: pointer;
}

.product-header .sz-button {
  width: 3vw;
  min-width: 50px;
}

.product-header {
  color: var(--white);
  margin: 10px 0px;
  background: var(--grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-header span {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.product-cards-xxl {
  display: grid;
  grid-template-columns: repeat(5, minmax(210px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}
.product-cards {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}
.product-cards-mob {
  display: grid;
  grid-template-columns: repeat(2, minmax(155px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}
.product-cards-4 {
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.product-cards-3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(155px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.product-list {
  margin-bottom: 10px;
}

.add-product-card {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-product-card .sz-button {
  width: 25%;
}
</style>