<template>
  <div class="container">
    <h1>Welcome {{ currentUser.username }}</h1>
  </div>
</template>
<script>
export default {
  name: 'Admin',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>

<style></style>