<template>
  <div class="about margin-body">

    <Head>
      <title>Shift Zone - About</title>
      <meta name="description" content="Contact us" />
    </Head>
    <h2>Reach us on facebook:</h2>
    <MqResponsive>
      <SZBtn @click="openFbLink">fb.com/shiftzone.tech</SZBtn>
    </MqResponsive>
    <h2>You may also contact us at:</h2>
    <MqResponsive>
      <h1><span class="mail">admin@shiftzone.tech</span></h1>
    </MqResponsive>
  </div>
</template>

<script>
import SZBtn from "@szc/app/SZBtn.vue";
import { MqResponsive } from "vue3-mq";
export default {
  name: "Support",
  inject: ["mq"],
  props: {
    centerText: String,
  },
  components: {
    MqResponsive,
    SZBtn
  },
  data() {
    return {
      templates: {

      }
    }
  },
  methods: {
    openLazLink() {
      window.open("https://www.lazada.com.ph/shop/shiftzonetech");
    },
    openShopeeLink() {
      window.open("https://shopee.ph/shiftzone.tech");
    },
    openFbLink() {
      console.log(this.mq)
      this.mq.mdPlus ? window.open("https://facebook.com/shiftzone.tech/") :
        window.location = "fb://page/101199492217741/";
    }
  },
};
</script>

<style scoped>
.templates {
  margin-top: 5vh;
}

.btn {
  cursor: pointer;
  font-size: 5vw;
  padding: 10px;
  border-radius: 1px;
}

.btn.mob {
  font-size: 7vw;
}

.contact-mails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.contact-mails .mail {
  font-size: 2em;
  margin-left: 1em;
}

</style>