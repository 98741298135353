<!-- eslint-disable no-unused-vars -->
<template>
  <div class="form-container">
    <Form @submit="handleSubmit" :validation-schema="schema">
      <div class="form-content">
        <label for="name">Name</label>
        <div class="field-col">
          <Field v-model="name" name="name" type="text" class="form-control" />
          <ErrorMessage name="name" class="error-feedback" />
        </div>

        <label for="name">Category</label>
        <div class="field-col">
          <input
            name="name"
            type="text"
            :value="$route.params.categoryId"
            readonly
          />
        </div>
      </div>

      <div class="button-div">
        <button class="sz-button">
          <span>Save</span>
        </button>

        <button
          v-if="sectionId"
          class="sz-button"
          @click="deleteSection"
          type="button"
        >
          <span>Delete</span>
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import SectionService from "../services/section.service";

export default {
  name: "Section",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data: () => {
    const schema = yup.object().shape({
      name: yup.string().required("Name is required!"),
    });
    return {
      name: "",
      schema: schema,
    };
  },
  methods: {
    handleSubmit(data) {
      data.sectionId = this.sectionId;
      SectionService.createSection(data, this.categoryId).then(
        (resp) => {
          var data = resp.data;
          var currentFilters = this.$store.getters.getFilter(this.categoryId);
          
          currentFilters.push(data.sectionId);
          this.$store.commit("updateFilter", {
              name: this.categoryId,
              data: currentFilters,
            });
            
          this.$router.push("/" + this.categoryId + "/products");
        }
      );
    },
    deleteSection() {
      SectionService.deleteSectionById(this.sectionId,  this.categoryId).then(() => {
        this.$router.push("/" +  this.categoryId + "/products");
      });
    },
  },
  mounted() {
    console.log("mount me");
    if (!this.sectionId) {
      return;
    }
    SectionService.getSectionById(this.sectionId,  this.categoryId).then((resp) => {
      var data = resp.data;
      this.name = data.name;
    });
  },
  computed: {
    sectionId() {
      return this.$route.params.sectionId;
    },
    categoryId() {
      return this.$route.params.categoryId;
    },
  },
};
</script>

<style scoped>
</style>