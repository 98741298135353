import axios from 'axios';
import authHeader from './auth-header';
const API_URL = 'http://localhost:8082/api/{category}/section/';
class SectionService {

  getPathWithCategory(category) {
    return API_URL.replace("{category}", category);
  }
  getSectionByCategory(category) {
    return axios.get(API_URL.replace("{category}", category));
  }
  createSection(section, category) {
    return axios.post(API_URL.replace("{category}", category), section, { headers: authHeader() });
  }
  getSectionById(sectionId, category) {
    return axios.get(API_URL.replace("{category}", category) + sectionId, { headers: authHeader() });
  }
  deleteSectionById(sectionId, category) {
    return axios.delete(API_URL.replace("{category}", category) + sectionId, { headers: authHeader() });
  }
}
export default new SectionService();