<template>
  <div id="Loading" v-if="isLoading">
    <div class="lds-dual-ring"></div>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
  },
  name: "SZLoader",
};
</script>

<style scoped>
#Loading {
  margin-top: 5vh;
}
</style>