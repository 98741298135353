import axios from 'axios';
import authHeader from './auth-header';
const API_URL = 'http://localhost:8082/api/product/';
class ProductApi {
  getProductById(id) {
    return axios.get(API_URL + id);
  }
  createProduct(product) {
    return axios.post(API_URL, product, { headers: authHeader() });
  }
  updateProduct(productId, product) {
    return axios.get(API_URL + productId, product, { headers: authHeader() });
  }
  deleteProduct(productId) {
    return axios.delete(API_URL + productId, { headers: authHeader() });
  }
}
export default new ProductApi();