<template>
  <div v-show="allImgsLoaded" ref="CarouselContainer">
    <div id="Carousel">
      <CarouselArrow @click="moveRight" v-if="hasMultipleImgs" left :width="mq.smMinus ? 32 : 64" :height="mq.smMinus ? 32 : 64" />

      <div id="CarouselImgs" ref="carousel" :style="{ right: computedPos }" :class="{ 'nondrag': !dragging }">
        <img class="carousel-img" v-for="img in imgs" :key="img.id" :src="mq.smMinus ? img.mobUrl : img.imgUrl"
          @pointerup="routeTo(img.route)" @pointerleave="leavePointer" @pointermove="dragImg" @pointerdown="startDrag"
          @load="setImageLoaded" />
      </div>

      <div class="dot-container">
        <CarouselDot v-for="(img, indx) in imgs" :key="img.id" :filled="indx == index" @click="jumpTo(indx)" />
      </div>

      <CarouselArrow @click="moveRight" v-if="hasMultipleImgs" :width="mq.smMinus ? 32 : 64" :height="mq.smMinus ? 32 : 64" />


    </div>
  </div>
  <div id="Loading" v-show="isLoadingImgs">
    <div class="lds-dual-ring"></div>
  </div>
</template>
<script>
import CarouselArrow from './CarouselArrow.vue';
import CarouselDot from './CarouselDot.vue';

export default {
  name: "Carousel",
  components: { CarouselArrow, CarouselDot },
  inject: ["mq"],
  props: {
    imgs: Array, // array of obj {img.id: "", imgUrl: "...", route: "...", mobUrl: "..."}
  },
  data: () => {
    return {
      index: 0,
      pos: 0,
      loadedImgSize: 0,
      dragging: false,
      dragX: 0,
      offsetX: 0,
      showDots: true
    };
  },
  methods: {
    moveLeft() {
      this.index = this.mod(--this.index, this.imgs.length);
      this.pos = this.index * this.carouselWidth;
      this.offsetX = 0;
    },
    moveRight() {
      this.index = this.mod(++this.index, this.imgs.length);
      this.pos = this.index * this.carouselWidth;
      this.offsetX = 0;
    },
    setImageLoaded() {
      this.loadedImgSize++;
    },
    mod(n, m) {
      return ((n % m) + m) % m;
    },
    leavePointer() {
      this.dragging = false;
      this.offsetX = 0;
    },
    routeTo(path) {
      if (this.dragging) {
        this.dragging = false;
        if (this.offsetX > this.carouselWidth / 3) {
          this.moveRight();
        } else if (this.offsetX < -this.carouselWidth / 3) {
          this.moveLeft();
        } else if (Math.abs(this.offsetX) < 1) {
          // due to removed touch events
          this.$router.push(path);
          return;
        }
        this.offsetX = 0;
        return;
      }
      this.$router.push(path);
    },
    dragImg(event) {
      if (!this.dragging) {
        return;
      }
      this.offsetX = (this.dragX - event.x);
      console.log(this.offsetX)

      // stops dragging to left on first image
      if (this.index == 0
        && this.offsetX < -this.carouselWidth / 3) {
        // minus 1 to trigger the page change
        this.offsetX = -this.carouselWidth / 3 - 1;
      } else if (this.index == this.imgs.length - 1
        && this.offsetX > this.carouselWidth / 3) {
        this.offsetX = this.carouselWidth / 3 + 11;
      }
    },
    startDrag(event) {
      this.dragging = true;
      this.dragX = event.x;
    },
    jumpTo(indx) {
      this.index = indx;
      this.pos = this.index * this.carouselWidth;
      this.offsetX = 0;
    }
  },
  computed: {
    carouselWidth() {
      return this.$refs.CarouselContainer.clientWidth;
    },
    computedPos() {
      return this.pos + this.offsetX + "px";
    },
    allImgsLoaded() {
      return this.imgs.length == this.loadedImgSize;
    },
    isLoadingImgs() {
      return this.imgs.length != this.loadedImgSize;
    },
    hasMultipleImgs() {
      return this.imgs.length > 1;
    }
  },
};
</script>
<style scoped>
#Carousel {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(159, 255, 208, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

#CarouselImgs {
  display: flex;
  position: relative;
  touch-action: none;
}

#CarouselImgs.nondrag {
  transition: all 0.5s ease-in-out;
}

#Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
}

.carousel-img,
#Loading {
  width: 100%;
  min-width: 100%;
  position: relative;
  cursor: grab;
}

.dot-container {
  position: absolute;
  margin: 0 auto;
  bottom: 10px;
  display: flex;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: rgba(0, 0, 0, 0.25);
}
</style>