<template>
  <transition name="card" mode="out-in" appear>
    <div class="product-card">
      <MqResponsive :target="['xs', 'sm']">
        <div class="img-container-mobile"  @click="goToDetails">
          <img
            :src="coverImg"
            :alt="altText"
            class="responsive mobile-img"
          />
        </div>
      </MqResponsive>

      <MqResponsive target="md+">
        <div class="img-container"  @click="goToDetails">
          <img :src="coverImg" :alt="altText" />
        </div>
      </MqResponsive>

      <div class="card-details">
        <MqResponsive target="md+">
          <div class="card-title">
            <span class="header name">{{ name }}</span>
            <span class="header price">
              <span v-if="isOnSale" class="orig-price">₱{{ getNormalPrice }}</span>
              <span>₱{{ getCurrentPrice }}</span>
            </span>
          </div>
        </MqResponsive>
        <MqResponsive :target="['xs', 'sm']">
          <div class="card-title">
            <span class="header name">{{ name }}</span>
            <span class="header price">
              <span v-if="isOnSale" class="orig-price">₱{{ getNormalPrice }}</span>
              <span>₱{{ getCurrentPrice }}</span>
            </span>
          </div>
        </MqResponsive>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    id: Number,
    name: String,
    imgUrl: String,
    shortDesc: String,
    altText: String,
    price: Number,
    salePrice: Number,
  },
  computed: {
    getCurrentPrice() {
      var currPrice = this.salePrice ? this.salePrice : this.price;
      return currPrice != null ? currPrice.toLocaleString("en", { useGrouping: true }) : "---";
    },
    getNormalPrice() {
      return this.price.toLocaleString("en", { useGrouping: true });
    },
    isOnSale() {
      return this.salePrice != null;
    },
    coverImg() {
      return this.imgUrl;
    }
  },
  methods: {
    goToDetails() {
      this.$router.push({name: "Product", params: {productId: this.id}});
    }
  },
};
</script>

<style scoped>
.product-card {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  flex-direction: column;
}

.card-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  padding: 15px;
  bottom: 0px;
  transition: 0.2s ease-in-out;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.card-details .name {
  font-size: min(100%, 1.25rem);
}

.card-details .price {
  font-size: min(100%, 1.25rem);
}

.orig-price {
  font-size: 0.65rem;
  text-decoration: line-through;
}

.img-container-mobile {
  display: flex;
  cursor: pointer;
}

.card-title {
  margin: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}


.card-desc {
  text-align: left;
  white-space: pre-line;
  margin: 0px 10px;
  min-height: 100px;
}

.card-desc-mob {
  text-align: left;
  white-space: pre-line;
  margin: 0px 25px;
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.card-enter-active {
  transition: all 0.1s ease-out;
}

.card-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.card-leave-active {
  transition: all 0.1s ease-in;
}

@media only screen and (max-width: 600px) {
  .product-card {
    margin-top: 20px;
  }
}

img.btn-link.disabled {
  cursor: default;
  opacity: 0.3;
}

img.btn-link {
  cursor: pointer;
}
</style>