<template>
  <SZLoader :isLoading="isLoading">
    <div class="margin-body form-container" v-if="isNewProduct">
      <Form @submit="handleSubmit" :validation-schema="schema">
        <div class="form-content">
          <label for="name">Name</label>
          <div class="field-col">
            <Field
              v-model="name"
              name="name"
              type="text"
              class="form-control"
            />
            <ErrorMessage name="name" class="error-feedback" />
          </div>
        </div>
        <div class="form-content">
          <label for="name">Description</label>
          <div class="field-col">
            <Field
              v-model="descrip"
              name="descrip"
              as="textarea"
              class="form-control"
              rows="3"
            />
            <ErrorMessage name="descrip" class="error-feedback" />
          </div>
        </div>
        <div class="form-content">
          <label for="name">Base Price</label>
          <div class="field-col">
            <Field
              v-model="price"
              name="price"
              type="number"
              class="form-control"
            />
            <ErrorMessage name="price" class="error-feedback" />
          </div>
        </div>
        <div class="form-content">
          <label for="name">Current Price</label>
          <div class="field-col">
            <Field
              v-model="currPrice"
              name="currPrice"
              type="number"
              class="form-control"
            />
            <ErrorMessage name="currPrice" class="error-feedback" />
          </div>
        </div>

        <div class="form-content">
          <label for="shopeeLink">Shopee</label>
          <div class="field-col">
            <Field name="shopeeLink" type="text" class="form-control" />
            <ErrorMessage name="currPrice" class="error-feedback" />
          </div>
        </div>

        <div class="form-content">
          <label for="shopeeLink">Lazada</label>
          <div class="field-col">
            <Field name="lazadaLink" type="text" class="form-control" />
            <ErrorMessage name="lazadaLink" class="error-feedback" />
          </div>
        </div>

        <div class="form-content">
          <label for="images">Images</label>
          <div class="field-col">
            <Field name="images" class="form-control">
              <input type="file" accept="image/*" multiple />
            </Field>
            <ErrorMessage name="images" class="error-feedback" />
          </div>
        </div>

        <div class="button-div">
          <button class="sz-button">
            <span>Save</span>
          </button>

          <button
            v-if="!isNewProduct"
            class="sz-button"
            @click="deleteSection"
            type="button"
          >
            <span>Delete</span>
          </button>
        </div>
      </Form>
    </div>

    <!-- product display -->
    <div id="ProductMain" class="margin-body" v-else>
      <div id="Product" :class="getMobClass">
        <div id="ImgContainer">
          <Carousel :imgs="imgUrls"></Carousel>
        </div>
        <div id="Details" :class="getMobClass">
          <div id="ItemName">
            <span> {{ name }} </span>
          </div>
          <div id="Price">
            <span v-if="isOnSale" class="orig-price"
              >₱{{ getNormalPrice }}</span
            >
            <span>₱{{ getPrice }}</span>
          </div>
          <div>
            <span id="Descrip"> {{ descrip }}</span>
          </div>

          <div id="Links">
            <span v-for="(value, key) in links" v-bind:key="key">
              <a :href="value"> {{ key }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </SZLoader>
</template>

<script>
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Carousel from "@szc/carousel/Carousel.vue";
import ProductService from "../services/product.service";
import SZLoader from "@szc/app/SZLoader.vue";

export default {
  name: "Product",
  components: {
    Form,
    Field,
    ErrorMessage,
    Carousel,
    SZLoader
  },
  inject: ["mq"],
  data: () => {
    const schema = yup.object().shape({
      name: yup.string().required(),
      descrip: yup.string().required(),
      price: yup.number().required().positive(),
      currPrice: yup.number().required().positive(),
      shopeeLink: yup.string().url(),
      lazadaLink: yup.string().url(),
    });
    return {
      isLoading: true,
      id: 0,
      name: "",
      descrip: "",
      price: 0,
      currPrice: 0,
      links: {
        shopee: "https://shopee.ph",
        lazada: "https://lazada.com.ph",
      },
      imgUrls: [],
      schema: schema,
    };
  },
  mounted() {
    if (!this.getProductId) {
      this.isLoading = false;
      return;
    }

    ProductService.getProductById(this.getProductId).then(
      (resp) => {
        var data = resp.data;
        this.id = data.id;
        this.name = data.name;
        this.price = data.price;
        this.currPrice = data.currPrice;
        this.descrip = data.descrip;
        this.links = data.links;
        this.imgUrls = [];
        data.images.forEach((imgData) => {
          var img = {
            id: imgData.productImageid,
            imgUrl: imgData.url,
            mobUrl: imgData.url,
          };

          this.imgUrls.push(img);
        });
        this.links = {};
        data.links.forEach((link) => {
          this.links[link.type] = link.url;
        });

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.$router.push("/Missing");
      }
    );
  },
  computed: {
    isNewProduct() {
      return this.$route.params.productId || this.$route.params.productId == "";
    },
    getProductId() {
      return this.$route.params.productId;
    },
    getMobClass() {
      return this.mq === "md" || this.mq === "sm" || this.mq === "xs"
        ? "mob"
        : "";
    },
    getPrice() {
      return this.currPrice.toLocaleString("en", { useGrouping: true });
    },
    getNormalPrice() {
      return this.price
        ? this.price.toLocaleString("en", { useGrouping: true })
        : "";
    },
    isOnSale() {
      return this.currPrice != this.price;
    },
  },
};
</script>

<style scoped>
#ProductMain {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5vh;
}

#ProductNew {
  margin-top: 5vh;
}

#BreadCrumbs {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

#ItemName {
  font-size: 2em;
}

#ImgContainer {
  min-width: 350px;
  max-width: 400px;
}

#Product {
  width: 100%;
  display: flex;
  flex-direction: row;
}

#Product div {
  margin-bottom: 10px;
}

#Product.mob {
  flex-direction: column;
  align-items: center;
}

#Product #Details {
  padding: 0 20px;
  text-align: left;
}

#Product #Details #Price {
  font-size: 2em;
  font-family: "Baron";
}

#Product #Details #Price .orig-price {
  font-size: 0.5em;
  font-family: "Baron";
  text-decoration: line-through;
}

#Product #Details.mob {
  padding: 20px 0px;
  text-align: left;
  align-self: flex-start;
}

#Links {
  width: 100%;
  display: flex;
  gap: 25px;
  font-size: 1.5em;
}

#Product #Details.mob #Descrip {
  font-size: 1.5em;
}

#Product #Details.mob #ItemName {
  font-size: 2.5em;
}
</style>