<template>
  <div class="btn-overlay btns left" v-if="left">
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" fill="var(--white)"
      class="bi bi-chevron-double-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
      <path fill-rule="evenodd"
        d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
    </svg>
  </div>
  <div class="btn-overlay btns right" v-else>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" fill="var(--white)"
      class="bi bi-chevron-double-right" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
      <path fill-rule="evenodd"
        d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CarouselArrow",
  props: {
    width: Number,
    height: Number,
    left: Boolean
  }
};
</script>

<style scoped>
.btn-overlay {
  position: absolute;
  z-index: 99;
  opacity: 0.75;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  padding: 10px;
}

.left {
  left: 1%;
}

.right {
  right: 1%;
}

.btns {
  color: var(--white);
  margin: 20px;
  cursor: pointer;
}
</style>

