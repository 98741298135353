<template>
  <div class="c-dot">
    <svg height="25" width="25">
      <circle cx="12" cy="12" r="5" stroke="white" stroke-width="2" :fill="fillVal" />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    filled: Boolean
  },
  computed: {
    fillVal() {
      return this.filled ? "white" : "transparent";
    }
  },
}
</script>
<style>
.c-dot {
  position: relative;
  display: flex;
  cursor: pointer;
}
</style>