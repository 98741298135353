<template>
  <div class="margin-body">
    <Head>
      <title>Shift Zone - {{ getMetaTitle }}</title>
      <meta name="description" :content="getMetaDesc" />
    </Head>
    <SZLoader :isLoading="isLoading">
      <SZFilter
        v-bind:categories="availableTags"
        @filter="filterCategories"
        :filterName="categoryId"
      ></SZFilter>
      <div v-if="filteredSubCatLength">
        <ProductList
          v-for="category in getFilteredSubCat"
          :key="category.name"
          v-bind:sectionName="category.name"
          v-bind:products="category.products"
          v-bind:editable="loggedIn"
          v-bind:sectionId="category.sectionId"
          @headerClicked="openSection"
        >
        </ProductList>
      </div>
      <div v-else>
        <h1>Select categories</h1>
      </div>

      <div id="AddSection" v-if="loggedIn">
        <div class="sz-button">
          <router-link :to="`/${categoryId}/section`">Add Section</router-link>
        </div>
      </div>
    </SZLoader>
  </div>
</template>

<script>
import ProductList from "@szc/catalog/ProductList.vue";
import SZFilter from "@szc/catalog/SZFilter.vue";
import SectionService from "../services/section.service";
import SZLoader from "@szc/app/SZLoader.vue";

export default {
  name: "Products",
  components: {
    ProductList,
    SZFilter,
    SZLoader,
  },
  methods: {
    getProductList() {
      return this.products[this.$route.params.categoryId];
    },
    getCategories() {
      return this.getProductList().map((category) => ({
        id: category.sectionId,
        name: category.name,
      }));
    },
    filterCategories(activeTags) {
      this.selectedTags = activeTags;

      this.filteredSubCat = [];
      this.selectedTags.forEach((tag) => {
        var section = this.getProductList().find((cat) => cat.sectionId == tag);

        if (section) {
          this.filteredSubCat.push(section);
        }
      });
    },
    initializeProducts(category) {
      console.log(category); // to be used once axios
      if (!category) {
        return; // exit route
      }

      this.isLoading = true;
      SectionService.getSectionByCategory(category).then(
        (resp) => {
          this.products[category] = resp.data;

          console.log(this.products);
          this.selectedTags = this.$store.getters.getFilter(category); // selected tags should be stored
          this.availableTagsData = this.getCategories();

          // initialize if null
          if (this.selectedTags === undefined) {
            this.selectedTags = this.availableTagsData.map((cat) => cat.id);
            this.$store.commit("updateFilter", {
              name: category,
              data: this.selectedTags,
            });
          }
          this.filterCategories(this.selectedTags);

          this.isLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    openSection(sectionId) {
      if (!this.loggedIn) {
        return;
      }
      this.$router.push("/" + this.categoryId + "/section/" + sectionId);
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getMetaDesc() {
      var categoryId = this.$route.params.categoryId;
      var desc = !this.metaDesc[categoryId]
        ? "Sim Racing and Fighting Game Accessories"
        : this.metaDesc[categoryId];
      return desc;
    },
    getMetaTitle() {
      var categoryId = this.$route.params.categoryId;
      var title = !this.metaTitle[categoryId]
        ? "Sim Racing and Fighting Game Accessories"
        : this.metaTitle[categoryId];
      return title;
    },
    getFilteredSubCat() {
      return this.filteredSubCat;
    },
    availableTags() {
      return this.availableTagsData;
    },
    filteredSubCatLength() {
      return this.filteredSubCat.length;
    },
    categoryId() {
      return this.$route.params.categoryId;
    },
  },
  watch: {
    "$route.params": {
      handler(newCategory) {
        this.initializeProducts(newCategory.categoryId);
      },
      immediate: true,
    },
  },
  data: () => {
    return {
      isLoading: true,
      selectedTags: [],
      filteredSubCat: [],
      availableTagsData: [],
      metaTitle: {
        race: "Sim Racing: Wheel Stand and Accessories",
        fight: "Arcade Stick, Mix box, Hit box",
      },
      metaDesc: {
        race: "Sim racing wheel stand with seat bracket, art cockpit, H shifter, sequential shifters.",
        fight:
          "Retro arcade crafts arcade stick, hitbox, mixbox. RAC J500k, RAC J500S, RAC J500B.",
      },
      products: {},
    };
  },
};
</script>

<style scoped>
.sz-filter {
  margin-top: 10px;
  height: auto;
}

#AddSection .sz-button {
  width: 20%;
  cursor: pointer;
  min-width: 100px;
}

#AddSection {
  display: flex;
  justify-content: center;
}
</style>